<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Graduate Roster</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label-for="search-session" label="Program 4 Session:">
                                    <b-input-group size="sm">
                                        <b-select id="search-session"
                                                  v-model="sessionFilter"
                                                  :options="sessionOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="sessionFilter = null">
                                                <font-awesome-icon icon="times" />
                                            </b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-for="graduation-year" label="Graduation Year:">
                                    <picker-of-dates id="graduation-year"
                                                     v-model="graduationYearFilter"
                                                     type="year"
                                                     format="yyyy"
                                                     placeholder="Select Year"
                                                     clearable/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import PickerOfDates from '@/components/shared/PickerOfDates';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import _ from 'underscore';
import {errorModalOptions, errorToastOptions} from "@/util/formatters";

@Component({
    components: {
        PickerOfDates
    }
})

export default class GraduateRoster extends Vue {
    sessionFilter = null;
    graduationYearFilter = null;

    clearFilters() {
        this.sessionFilter = null;
        this.graduationYearFilter = null;
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        try {
            const params = {
                workshopInstanceId: this.sessionFilter,
                graduationYear: this.graduationYearFilter
            };
            // console.log(params);
            await download('POST', '/api/v1/report/graduate-roster', JSON.stringify(params));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (!this.sessionFilter && !this.graduationYearFilter) {
            this.$bvModal.msgBoxOk('Please enter at least one search criteria.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/program4Sessions'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Graduate Roster', null, true));
        try {
            await this.$store.dispatch('common/loadProgram4Sessions');
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

}
</script>


<style scoped>
</style>
